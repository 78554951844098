import React, { useState, useEffect } from "react";
import "../../components/Main/MainComponents.css";
import LoadingBars from "../../utils/LoadingBars";
// import { Link } from "react-router-dom";
import "./CardSF.css";
import {
  ticketStatus,
  ticketColor,
  ticketDate,
} from "../../utils/ticketStatus";
//custom exceptions

import NotFound from "../NotFound/NotFound";

const CardSF = ({ title, iconSolic, tab1, detalles }) => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(false);
  const id = detalles?.id

  useEffect(() => {
    setLoading(true);
    setTimeout(async () => {
      if (id) {
        const searchUser = async (id) => {
          const searchZendeskUser = `${process.env.REACT_APP_URL}search-zendesk-user?externalId=${id}`;
          const findUser = await fetch(searchZendeskUser);
          if (findUser.ok) {
            const data = await findUser.json();
            try {
              const { id } = await data;
              const zendeskTickets = `${process.env.REACT_APP_URL}search-tickets?userId=${id}&page=1`;
              fetch(zendeskTickets)
                .then((response) => response.json())
                .then((data) => {
                  setUser(data)
                  return setLoading(false)
                })
                .catch((error) => setLoading(false));
            } catch (error) {
              console.error(error);
            }
          }
        };
        searchUser(id)
      }
    }, 2000);
  }, [id]);

  const TicketInformation = ({ tickets }) => {
    return (
      <>
        {tickets?.map((ticket) => {
          return (
            <tr
              className="card-row"
              key={ticket.id}
              data-bs-toggle={`modal`}
              data-bs-target={`#exampleModal${ticket.id}`}>
              <td className="d-flex">
                <i className="fas fa-envelope me-2"></i>
                {ticketDate(ticket.created_at)}
              </td>
              <td>{ticket.subject}</td>
              <td className={`fw-bold ${ticketColor(ticket.status)}`}>
                {ticketStatus(ticket.status)}
              </td>
              <div
                className={`modal fade modal-sa`}
                id={`exampleModal${ticket.id}`}
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title cardsa-title" id="exampleModalLabel">
                        {ticket.subject}
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body ">
                      <p className="link-txt">Ticket Nro: {ticket.id}</p>
                      <hr className="divider" />
                      <p className="link-txt">Ticket creado el día:
                        <span> {ticketDate(ticket.created_at)}</span>
                      </p>
                      <hr className="divider" />
                      <p className="link-txt">Ticket actualizado el día:
                        <span> {ticketDate(ticket.updated_at)}</span>
                      </p>
                      <hr className="divider" />
                      <p className="link-txt">Estado actual del ticket:
                        <span className={`fw-bold ${ticketColor(ticket.status)}`}> {ticketStatus(ticket.status)}</span>
                      </p>
                      <hr className="divider" />
                    </div>
                  </div>
                </div>
              </div>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <div className="card card-sf">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="mb-0 cardsf-title">{title}</h4>
        </div>

        <div className="d-flex flex-column h-100">
          {!loading && !user.length ? (

            <NotFound
              message={"No se han encontrado solicitudes."}
              icon={"fa-solid fa-circle-xmark icon-error"}
              textStyles={"text-error"}
            />

          ) : (
            <>
              <table className="table1 w-100 mt-4 text-left table-responsive">
                <thead>
                  <tr>
                    <th className="pb-2">Fecha</th>
                    <th className="pb-2">Asunto</th>
                    <th className="pb-2">Estado</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <LoadingBars bars={7} />
                  ) : (
                    <TicketInformation tickets={user} />
                  )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardSF;
