import React, { Component } from "react";
import "../../components/Main/MainComponents.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import NotFound from "../NotFound/NotFound";

export default class CardFac extends Component {
  state = {
    facturas: null,
    fact_imp: null,
    factpdf: null,
    test: null,
  };

  componentDidMount() {
    this.getFacturas();
  }

  getFacturas() {
    if (this.state.facturas === null) {
      fetch(`${process.env.REACT_APP_URL}facturas`)
        .then((response) => response.json())
        .then((data) => {
          if (data.fact.length !== 0 && data.fc1.length !== 0) {
            this.setState({ facturas: data.fact, fact_imp: data.fc1 }, () => {
              this.PdfDecode();
            });
          } else {
            this.setState({ facturas: 0 });
          }
        });
    }
  }

  PdfDecode() {
    var base64str;
    let copy = [];
    var PdfUrl;
    let p = [];

    for (var i = 0; i < this.state.fact_imp.length; i++) {
      base64str = this.state.fact_imp[i];
      PdfUrl = "data:application/pdf;base64," + encodeURI(base64str);
      copy.push(PdfUrl);
    }

    for (var j = 0; j < this.state.facturas.length; j++) {
      p.push({ ...this.state.facturas[j], pdf: copy[j] });
    }

    this.setState({ facturas: p });
  }

  render() {
    return (
      <div className="card card-sf">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 cardsf-title">Facturas</h4>
          </div>

          <div className="d-flex flex-column h-100">
            {this.state.facturas === 0 ? (
             
                <NotFound
                  message={"No se han encontrado facturas."}
                  icon={"fa-solid fa-circle-xmark icon-error"}
                  textStyles={"text-error"}
                />
              
            ) : (
              <>
                <table className="table1 w-100 mt-4 text-left table-responsive">
                  <thead>
                    <tr>
                      <th className="pb-2">Fecha</th>
                      <th className="pb-2">Número de Ref</th>
                      <th className="pb-2">Monto</th>
                      <th className="pb-2"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.facturas &&
                      this.state.facturas.map((intr) => {
                        return (
                          <tr key={intr.NFactura}>
                            <td>
                              {new Date(intr.FechaEmision).toLocaleDateString()}
                            </td>
                            <td>{intr.NFactura}</td>
                            <td>
                              $
                              {new Intl.NumberFormat("de-DE").format(
                                intr.Monto
                              )}
                            </td>

                            <td>
                              <a
                                className="link-pdf"
                                download={`factura-N°${intr.NFactura}`}
                                href={intr.pdf}
                                title="Download pdf document"
                              >
                                <i className="fas fa-download link-pdf "></i>
                              </a>
                            </td>
                          </tr>
                        );
                      })}

                    {this.state.facturas === null && (
                      <>
                        {[...Array(7)].map((x, i) => (
                          <tr key={i}>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                            <td>
                              <Skeleton />
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
