import React from "react";
// import NotFound from "../../../../shared/NotFound/NotFound";
// import LoadingBars from '../../../../utils/LoadingBars'

const Chart1 = ({ image, loading }) => {
  return (
    <div className="w-100 d-flex h-100">
      {/* {image !== null ? ( */}
      


        {
          loading === true && (
            <div
            className="spinner-border spinner-border-lg mt-3 mx-auto"
            role="status"
          >
            <span className="sr-only">Cargando...</span>
          </div>
          )
        }



        {loading === false && image !== undefined && (
            <img
            src={`data:image/jpeg;base64,${image}`}
            alt="grafica"
            className="chart mt-4"
          />
        )}
      {/* ) : (
        <div className="mx-auto my-auto">
          <NotFound
            message={
              "No se encontraron servicios dedicados asociados a su número de identificación."
            }
            icon={"fa-solid fa-circle-xmark icon-error"}
            textStyles={"text-error"}
          />
        </div>
      )} */}
    </div>
  );
};

export default Chart1;
