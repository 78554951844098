import React, { useEffect, useState, useRef } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Head from "../../shared/Head";
// import Sidebar from "../../shared/Sidebar/Sidebar";
import Button from "../../shared/Button/Button";
import "./PagosM.css";
import * as XLSX from "xlsx";
import DataPreview from "./DataPreview";
import { useHistory } from "react-router-dom";
import Modal1 from "../../components/Main/Modal/Modal";
import closeicon from "../../assets/svg/close-icon.svg";
import excel from "../../assets/excel/Pagos.xlsx";
import Popover from "../../shared/Popover/Popover";

function PagosM() {
  const [items, setItems] = useState([]);
  const [dbt, setD] = useState(null);
  const [modal, setModal] = useState(false);
  const [sent, setSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const Open = () => {
    setModal(!modal);

    if (sent === true) {
      redirect();
    }
  };

  const getDate = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `05/${month}/${year}`;
  };

  const [msj, setMsj] = useState("");
  const [icon, setIcon] = useState("");
  const [err, setErr] = useState(false);

  /* */

  const history = useHistory();
  const ref = useRef();
  const redirect = () => {
    history.push("/dashboard");
  };

  const validationSchema = Yup.object().shape({
    NumeroDePagos: Yup.number().required("Este valor es requerido.").integer(),
    pagos: Yup.array().of(
      Yup.object().shape({
        NumeroTransferencia: Yup.string().required("Este valor es requerido."),
        Comentario: Yup.string().required("Este valor es requerido."),
      })
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, control, reset, handleSubmit, formState, watch } =
    useForm(formOptions);
  const { errors } = formState;
  const { fields, append, remove } = useFieldArray({
    name: "pagos",
    control,
  });

  const NumeroDePagos = watch("NumeroDePagos");

  useEffect(() => {
    const newVal = parseInt(NumeroDePagos || 0);
    const oldVal = fields.length;

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        append(
          { NumeroTransferencia: "", Comentario: "" },
          { shouldFocus: false }
        );
      }
    } else {
      for (let i = oldVal; i > newVal; i--) {
        remove(i - 1);
      }
    }

    if (
      err === true &&
      NumeroDePagos !== "" &&
      NumeroDePagos > 1 &&
      NumeroDePagos <= 20
    ) {
      setErr(false);
    }

    if (NumeroDePagos > 20 || NumeroDePagos < 0) {
      setErr(true);
    }

    if (err === true && NumeroDePagos === undefined) {
      setErr(false);
    }

    if (NumeroDePagos !== "") {
      setItems([]);
    }

    gdbt();
  }, [NumeroDePagos, append, remove, fields.length, err]);

  function onSubmit(data) {
    setIsLoading(true);

    var values = [];

    if (data.pagos.length > 0) {
      values = data.pagos;

      const lookup = values.reduce((a, e) => {
        a[e.NumeroTransferencia] = ++a[e.NumeroTransferencia] || 0;
        return a;
      }, {});

      values = values.filter((e) => lookup[e.NumeroTransferencia]);

      if (values.length === 0) {
        fetch(`${process.env.REACT_APP_URL}reportar-pm`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            NumeroTransferencia: data.pagos,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            setIsLoading(false);

            if (data.error) {
              throw new Error("Bad response from server");
            }

            if (data.status === 204) {
              setSent(true);
              setMsj(
                "El registro de su pago ha sido efectuado de manera exitosa."
              );
              setIcon(`fas fa-check-circle modal-icon-success`);
              Open();
            }
            if (data.status === 400) {
              var x = JSON.parse(data.msg);
              var index = 4;

              x = x.substring(0, index) + "ú" + x.substring(index + 1);

              x = x.replace("verificacion", "transferencia");
              setMsj(`${x}.`);
              setIcon(`far fa-times-circle modal-icon-error`);
              Open();
              setItems([]);
            }
          })
          .catch((error) => {
            console.error("Error:", error);
            setMsj(`Su solicitud no pudo ser procesada.`);
            setIcon(`far fa-times-circle modal-icon-error`);
            Open();
          });
      } else {
        setMsj(
          `El número de transferencia ${values[0].NumeroTransferencia} está duplicado.`
        );
        setIcon(`far fa-times-circle modal-icon-error`);
        Open();
      }
    }
  }

  function gdbt() {
    let buff = new Buffer(localStorage.getItem("dbt-0"), "base64");
    let base64ToStringNew = buff.toString("ascii");
    setD(base64ToStringNew);
  }

  const clearFile = () => {
    ref.current.value = "";
    setItems([]);
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d) => {
      if (d.length !== 0) {
        var checkKeys = d.filter((t) =>
          Object.keys(t).includes("NumeroTransferencia", "Comentario")
        ).length;
        if (checkKeys === d.length) {
          setItems(d);
        } else {
          setMsj(`El arhivo debe contener el formato establecido.`);
          setIcon(`far fa-times-circle modal-icon-error`);
          Open();
          reset();
        }
      } else {
        setMsj(`No se ha podido adjuntar el archivo.`);
        setIcon(`far fa-times-circle modal-icon-error`);
        Open();
        reset();
      }
    });
  };

  function sendExcel() {
    setIsLoading(true);

    if (items.length !== 0) {
      fetch(`${process.env.REACT_APP_URL}reportar-pm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          NumeroTransferencia: items,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setIsLoading(false);

          if (data.error) {
            throw new Error("Su solicitud no pudo ser procesada.");
          }
          if (data.status === 204) {
            setSent(true);
            setMsj(
              "El registro de su pago ha sido efectuado de manera exitosa."
            );
            setIcon(`fas fa-check-circle modal-icon-success`);
            Open();
          }
          if (data.status === 400) {
            var x = JSON.parse(data.msg);
            var index = 4;
            x = x.substring(0, index) + "ú" + x.substring(index + 1);
            x = x.replace("verificacion", "transferencia");

            setMsj(`${x}.`);
            setIcon(`far fa-times-circle modal-icon-error`);
            Open();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          setMsj(`Su solicitud no pudo ser procesada.`);
          setIcon(`far fa-times-circle modal-icon-error`);
          Open();
        });
    }
  }

  function test() {
    if (items.length === 0 && NumeroDePagos !== "") {
      setItems([]);
      handleSubmit(onSubmit)();
    } else if (items.length > 1 && NumeroDePagos === "") {
      sendExcel();
    } else {
      setMsj(`Seleccione una de las opciones para reportar su pago.`);
      setIcon(`far fa-times-circle modal-icon-error`);
      Open();
    }
  }

  return (
    <div className="wrapper w-100 d-flex align-items-stretch">
      {/* <Sidebar /> */}
      <div id="content" className="pagosm-cnt">
        <div className="container main-ct w-100 p-4 pt-2">
          <Head title={"Reporte de Pago"} myClassN={"mb-0 resumen pagosm-h"} />
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="table-responsive-xl d-tbl">
              <table className="w-100 mt-4 pm-table ">
                <thead className="thd">
                  <tr className="tbl-rw">
                    <th className="th-pm">Deuda Acumulada</th>
                    <th className="th-pm">Vencimiento</th>
                    <th className="th-pm">Cantidad de Pagos a Reportar</th>
                    <th className="th-pm">Planilla Excel</th>
                    <th className="th-pm">Importar Excel</th>
                    <th className="th-pm">Reporta tu Pago</th>
                  </tr>
                </thead>
                <tbody className="tbdy">
                  <tr className="tbl-rw">
                    <td
                      data-column="Deuda Acumulada"
                      className="td-pm cell-dbt"
                    >
                      ${dbt}
                    </td>
                    <td data-column="Vencimiento" className="td-pm cell-vt">
                      {getDate()}
                    </td>

                    <td
                      data-column="Cantidad de Pagos a Reportar"
                      className="td-pm d-flex"
                    >
                      <form className="d-flex d-ig">
                        <div className="form-group pm-card">
                          <input
                            type="number"
                            min="1"
                            max="20"
                            name=""
                            placeholder="Colocar Cantidad"
                            {...register("NumeroDePagos")}
                            className={`form-control text-center formc-pm ${
                              errors.NumeroDePagos ? "is-invalid" : ""
                            }`}
                          ></input>
                          <div className="invalid-feedback">
                            {errors.NumeroDePagos?.message}
                          </div>
                        </div>

                        {fields.length > 0 && (
                          <button
                            onClick={() => reset()}
                            className="btn p-0 ms-2"
                          >
                            <img
                              src={closeicon}
                              alt="closeicon"
                              width={20}
                              height={20}
                            />
                          </button>
                        )}

                        {err && <p className="alert-np mb-0">⚠</p>}
                      </form>
                    </td>

                    <td data-column="Planilla Excel" className="td-pm">
                      <div className="pm-card">
                        <a
                          onMouseOver={handleMouseOver}
                          onMouseOut={handleMouseOut}
                          className="filed-excel"
                          href={excel}
                          download={"Pagos"}
                          id="fileToDownload"
                        >
                          <i className="fa-solid fa-circle-down me-2"></i>
                          Descargar
                        </a>
                      </div>
                      {isHovering && (
                        <Popover
                          title={"Instrucciones para Carga Masiva "}
                          MainClass={"popover-c"}
                          titleClass={"popover-title"}
                          text={[
                            <ol key={"1"} className="steps-excel">
                              <li>Descargue el Archivo Pagos.xlsx </li>
                              <li>
                                Usando Microsoft Excel, abra el Archivo
                                Pagos.xls
                              </li>
                              <li>
                                A partir de la línea 2, para cada reporte de
                                pago complete los campos:{" "}
                                <span className="fw-bold">
                                  NúmeroTransferencia y Comentario
                                </span>
                              </li>
                              <li>
                                Verifique que toda la información esté completa
                                y no haya información duplicada
                              </li>
                              <li>Guarde el Documento</li>
                              <li>
                                Cargue el archivo Pagos.xlsx a través de la
                                opción
                                <span className="fw-bold"> Subir Archivo</span>.
                              </li>
                            </ol>,
                          ]}
                        />
                      )}
                    </td>

                    <td data-column="Importar Excel" className="td-pm">
                      <div className="pm-card">
                        <label
                          className={`file-upload ${
                            items.length > 1 ? `disabled-pm` : ``
                          }`}
                        >
                          <input
                            type="file"
                            ref={ref}
                            multiple="multiple"
                            name="fileToUpload"
                            id="fileToUpload"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              readExcel(file);
                            }}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                          />
                          <i className="fas fa-arrow-circle-up me-2"></i>Subir
                          Archivo
                        </label>

                        {items.length > 0 && (
                          <button onClick={clearFile} className="btn p-0 ms-2">
                            <img
                              src={closeicon}
                              alt="closeicon"
                              width={20}
                              height={20}
                            />
                          </button>
                        )}
                      </div>
                    </td>

                    <td data-column="Reporta tu Pago" className="td-pm">
                      <div className="pm-card">
                        <Button
                          text={"Reportar"}
                          myClass={"btn btn-primary btn-pm rounded-pill "}
                          leftIcon={"fas fa-redo me-3"}
                          funct={test}
                          loading={isLoading}
                          loadingMsg={"Reportando Pago"}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="row">
              <div className="container-input">
                {items.length > 1 && NumeroDePagos === "" && (
                  <DataPreview data={items} />
                )}

                {fields.length > 0 &&
                  fields.length <= 20 &&
                  fields.map((field, i) => (
                    <div key={i} className={`card card-pm mt-4 ${field.id}`}>
                      <div className="card-title">
                        <div className="form-row">
                          <div className="form-group">
                            <label className="text-dark r-label">
                              Número de Transferencia
                            </label>
                            <input
                              name={`pagos[${i}]NumeroTransferencia`}
                              {...register(`pagos.${i}.NumeroTransferencia`)}
                              className={`form-control ${
                                errors.pagos?.[i]?.NumeroTransferencia
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <div className="invalid-feedback">
                              {errors.pagos?.[i]?.NumeroTransferencia?.message}
                            </div>
                          </div>
                          <div className="form-group">
                            <label className="text-dark r-label">
                              Comentario
                            </label>
                            <input
                              name={`pagos[${i}]Comentario`}
                              {...register(`pagos.${i}.Comentario`)}
                              className={`form-control ${
                                errors.pagos?.[i]?.Comentario
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <div className="invalid-feedback">
                              {errors.pagos?.[i]?.Comentario?.message}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal1 open={modal} msj={msj} close={Open} icon={icon} />
    </div>
  );
}

export { PagosM };
