import React from "react";
import "../../components/Main/MainComponents.css";
import logo from "../../assets/svg/logo-airtek.svg";
// import NavbarIcon from "./NavbarIcon";
import { Link } from "react-router-dom";

const NavbarRsp = () => {
  function logout() {
    window.location.replace("/");
    localStorage.clear();
  }

  return (
    <nav className="navbar  navbar-responsive">
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <Link to="/dashboard" aria-current="page">
          <img
            className="navbar-brand"
            src={logo}
            width={170}
            height={40}
            alt="logo airtek"
          ></img>
        </Link>

        {/* <div className="ms-auto d-flex align-items-center">
          <NavbarIcon icon={"fas fa-bell"} notif />
          <img
            src={`https://images.pexels.com/photos/753339/pexels-photo-753339.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940`}
            width="40"
            height="40"
            className="rounded-circle position-relative pfp"
            alt="pfp"
          />
        </div> */}

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 mt-3">
            <li className="nav-item">
              <Link
                to="/dashboard"
                className="nav-link active"
                aria-current="page"
              >
                <i className="fas fa-border-all me-3 nr-icon"></i>Resumen
              </Link>
            </li>
            {/*
            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page">
                <i className="fas fa-file-alt me-3 nr-icon"></i>
                Reportes
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page">
                <i className="fas fa-server me-3 nr-icon"></i>
                Servicios
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page">
                <i className="fas fa-cube me-3 nr-icon"></i>
                Inventario
              </Link>
            </li>

            <li className="nav-item">
              <Link to="/" className="nav-link" aria-current="page">
                <i className="far fa-life-ring me-3 nr-icon"></i>
                Soporte
              </Link>
            </li> */}

            <li className="nav-item dropdown">
              <Link
                to="/"
                className="nav-link dropdown-toggle"
                href="#"
                id="smal"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fas fa-cog me-3 nr-icon"></i>
                Configuración
              </Link>

              <ul className="dropdown-menu dm-2" aria-labelledby="smal">
                <li className="nav-item c-pwd">
                  <Link
                    to="/recovery"
                    className="nav-link c-pwd"
                    aria-current="page"
                  >
                    <i className="fas fa-lock me-3 nr-icon"></i>
                    Cambiar Contraseña
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item" onClick={logout}>
              <p className="nav-link logout-rsp mb-0">
                <i className="fas fa-sign-out-alt me-3 nr-icon"></i>
                Salir
              </p>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavbarRsp;
