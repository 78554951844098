import React, { useEffect, useState } from "react";
import video from "../../assets/videos/pwd-vid.mp4";
import Button from "../../shared/Button/Button";
import "./RecoverPwd.css";
import vector from "../../assets/svg/Vector.svg";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Modal1 from "../../components/Main/Modal/Modal";
import Modal2 from "../../components/Main/Modal/Modal";

const RecoverPwd = () => {
  const validationSchema = Yup.object().shape({
    Password: Yup.string()
      .required('El campo "Contraseña actual" es requerido.      *')
      .min(8, "Su contraseña debe tener al menos 8 caracteres.")
      .max(12, 'El campo "Contraseña actual" tiene un límite máximo de 12 caracteres.'),
    Re_Password: Yup.string()
      .required('El campo "Nueva contraseña" es requerido.      *')
      .min(8, "Su contraseña nueva debe tener al menos 8 caracteres.")
      .max(12, 'El campo "Nueva contraseña" tiene un límite máximo de 12 caracteres.')
      .notOneOf(
        [Yup.ref("Password")],
        "La contraseña nueva debe ser distinta de la contraseña anterior."
      )
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "La contraseña debe tener al menos 1 número, 1 carácter especial, 1 carácter en MAYÚSCULAS y 1 carácter en minúscula."
      ),
    Re_Password2: Yup.string()
      .required('El campo "Confirmar contraseña" es requerido.      *')
      .max(12, 'El campo "Confirmar contraseña" tiene un límite máximo de 12 caracteres.')
      .oneOf(
        [Yup.ref("Re_Password")],
        'Los campos "Nueva Contraseña" y "Confirmar Contraseña" no coinciden.'
      ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [email, setEmail] = useState("");

  const redirect = () => {
    history.push("/dashboard");
  };

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [sent, setSent] = useState(false);
  const [showButtons, setButtons] = useState(false);
  const [next, setNext] = useState(false);

  const Open = () => {
    setModal(!modal);

    if (sent === true) {
      redirect();
    }
  };

  const Open2 = () => {
    setModal2(!modal2);
  };

  const cambiar = () => {
    setMsj("¿Desea cambiar su contraseña?");
    setButtons(true);
    setIcon(`fas fa-exclamation-circle modal-icon-success`);
    Open2();
    setNext(true);
  };

  const [msj, setMsj] = useState("");
  const [icon, setIcon] = useState("");

  const onSubmit = (data) => {
    if (data) {
      cambiar();

      if (next) {
        setTimeout(() => {
          Open2();
        }, 660);

        data = { ...data, UserName: email };

        fetch(`${process.env.REACT_APP_URL}password-reset`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            info: data,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.error) {
              throw new Error("Bad response from server");
            }

            if (data.status === 204) {
              setSent(true);
              setMsj("La contraseña se ha cambiado exitosamente.");
              setIcon(`fas fa-check-circle modal-icon-success`);
              Open();
            }
            if (data.status === 400) {
              setMsj("Hubo un percance con los datos enviados.");
              setIcon(`far fa-times-circle modal-icon-error`);
              setNext(false);
              Open();
            }
            if (data.status === 404) {
              setMsj("La contraseña actual que especificaste es incorrecta. Inténtalo de nuevo.");
              setIcon(`far fa-times-circle modal-icon-error`);
              setNext(false);
              Open();
            }
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      }
    }
  };

  useEffect(() => {
    let buff = new Buffer(localStorage.getItem("username"), "base64");
    let base64ToStringNew = buff.toString("ascii");
    setEmail(base64ToStringNew);
  }, []);

  const history = useHistory();
  return (
    <>
      <nav className="navbar npwd">
        <div className="container ct">
          <div className="d-flex nvb-pwd">
            <button
              onClick={() => {
                history.goBack();
              }}
              className="regresar"
            >
              <img src={vector} alt="regresar" className="me-3" />
              Regresar
            </button>
          </div>
        </div>
      </nav>
      <div className="container ct">
        <div className="row gx-1">
          <div className="col-lg-6 p-left">
            <div className="d-flex l-container">
              <div className="my-auto mx-auto">
                <h6 className="rec-pwd">
                  Restablecer <br />
                  contraseña
                </h6>
                <p className="p-pwd">
                  Ingrese los valores solicitados para proceder con el
                  <span className="fw-bold"> cambio de clave.</span>
                </p>

                <div className="i-container">
                  <div
                    className={`input-group2 ${
                      errors.Password ? "is-invalid" : ""
                    }`}
                  >
                    <input
                      placeholder="Contraseña actual"
                      type="password"
                      {...register("Password")}
                      className={`input-pwd ${
                        errors.Password ? "is-invalid" : ""
                      }`}
                    />
                    <div className="input-group-prepend">
                      <span
                        className={`input-group-text-pwd ${
                          errors.Password ? "is-invalid" : ""
                        }`}
                      >
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <span className="fa-stack-1x numb">1</span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <ErrorMessage
                    errors={errors}
                    name="Password"
                    className="lg-error1"
                    as="p"
                  />

                  <div
                    className={`input-group2 ${
                      errors.Re_Password ? "is-invalid" : ""
                    }`}
                  >
                    <input
                      placeholder="Nueva contraseña"
                      type="password"
                      {...register("Re_Password")}
                      className={`input-pwd ${
                        errors.Re_Password ? "is-invalid" : ""
                      }`}
                    />
                    <div className="input-group-prepend">
                      <span
                        className={`input-group-text-pwd ${
                          errors.Re_Password ? "is-invalid" : ""
                        }`}
                      >
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <span className="fa-stack-1x numb">2</span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <ErrorMessage
                    errors={errors}
                    name="Re_Password"
                    className="lg-error1"
                    as="p"
                  />

                  <div
                    className={`input-group2 ${
                      errors.Re_Password2 ? "is-invalid" : ""
                    }`}
                  >
                    <input
                      placeholder="Confirmar contraseña"
                      type="password"
                      {...register("Re_Password2")}
                      className={`input-pwd ${
                        errors.Re_Password2 ? "is-invalid" : ""
                      }`}
                    />
                    <div className="input-group-prepend">
                      <span
                        className={`input-group-text-pwd ${
                          errors.Re_Password2 ? "is-invalid" : ""
                        }`}
                      >
                        <span className="fa-stack">
                          <i className="fas fa-circle fa-stack-2x"></i>
                          <span className="fa-stack-1x numb">3</span>
                        </span>
                      </span>
                    </div>
                  </div>

                  <ErrorMessage
                    errors={errors}
                    name="Re_Password2"
                    className="lg-error1"
                    as="p"
                  />

                  <Button
                    text={"cambiar contraseña"}
                    myClass={"btn btn-dark rounded-pill btn-rec"}
                    funct={handleSubmit(onSubmit)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 d-none d-lg-block bg-right d-flex">
            <div className="d-flex">
              <video autoPlay={true} loop className="my-auto pwd-vid">
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
        <div className="row ft-pwd">
          <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
            <p className="mb-0 pwd-f l-1">
              Tu clave de acceso es personal e intransferible.
            </p>
            <p className="mb-0 pwd-f l-2">
              La contraseña debería tener como mínimo de 8 a 12 caracteres
              alfanuméricos.
            </p>
            <p className="mb-0 pwd-f l-3">
              Evita utilizar palabras o nombres que sean fáciles de adivinar.
            </p>
          </div>
        </div>
        <Modal1 open={modal} msj={msj} close={Open} icon={icon} />

        <Modal2
          open={modal2}
          msj={msj}
          close={Open2}
          icon={icon}
          buttons={showButtons}
          funct={handleSubmit(onSubmit)}
          funct2={Open2}
        />
      </div>
    </>
  );
};

export default RecoverPwd;
