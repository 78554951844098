import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Login.css";
import logodark from "../../assets/img/logo-dark.png";
import Button from "../../shared/Button/Button";
import { useHistory } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";

export default function Login() {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [ls, setLs] = useState(false);
  const [err, setErr] = useState();
  const [hide, setHide] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem("isLogedIn", ls);

    if (hide === false) {
      setTimeout(() => {
        setHide(true);
      }, 2000);
    }

  }, [ls, hide]);

  const onSubmit = (data) => {
    setIsLoading(true);

    fetch(`${process.env.REACT_APP_URL}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user: {
          email: data.email,
          password: data.password,
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.error) {
          setIsLoading(false);
          throw new Error("Bad response from server");
        }
        if (json !== undefined) {
          setLs(true);
          
          localStorage.setItem(
            "username",
            Buffer.from(data.email).toString("base64")
          );


          localStorage.setItem("isLogedIn", ls);
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setHide(false);
        setIsLoading(false);
        setErr(
          "Se produjo un error al iniciar sesión. Vuelve a intentarlo más tarde."
        );
        localStorage.setItem("isLogedIn", ls);
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-6 d-none d-lg-block bg-left">
          <div className="d-md-flex align-items-center h-100 p-5 text-center justify-content-center">
            <div className="logoarea pt-5 pb-5">
              <p className="mb-0 left-text">
                Las
                <br />
                buenas
                <br />
                conexiones inspiran ideas increíbles
              </p>
              <p className="mb-0 l-txt">es internet</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 login ">
          <div className="d-md-flex align-items-center  text-center justify-content-center cont-lg">
            <div></div>
            <div className="login-dv mx-auto align-items-center text-center">
              <img src={logodark} className="logo-dark" alt="logo-dark" />

              <div className="text-center">
                <input
                  type="email"
                  className={`email-input  ${errors.email ? "is-invalid" : ""}`}
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Correo electrónico"
                  {...register("email", {
                    required: "El campo correo electrónico es obligatorio *",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message:
                        "Ingrese una dirección de correo electrónico válida(por ejemplo, airtek@airtek.com.ve).",
                    },
                  })}
                />

                <input
                  type="password"
                  className={`pwd-input  ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  id="exampleInputPassword1"
                  placeholder="Contraseña"
                  {...register("password", {
                    required: "El campo contraseña es obligatorio *",
                  })}
                />

                <ErrorMessage
                  className="lg-error"
                  errors={errors}
                  name="password"
                  as="p"
                />

                <ErrorMessage
                  errors={errors}
                  name="email"
                  className="lg-error"
                  as="p"
                >
                  {({ messages }) => {
                    console.log(messages);
                    return (
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p className="lg-error" key={type}>
                          {message}
                        </p>
                      ))
                    );
                  }}
                </ErrorMessage>

                {err !== undefined && hide === false && (
                  <div className="lg-is-error">
                    <p className="lg-error">{err}</p>
                  </div>
                )}

                <Button
                  text={"Iniciar Sesión"}
                  myClass={"btn btn-dark rounded-pill w-100 btn-login"}
                  funct={handleSubmit(onSubmit)}
                  loading={isLoading}
                  loadingMsg={"Iniciando Sesión"}
                />
              </div>
            </div>
          </div>

          <div className="footer-a">
            <div className="text-center align-items-center">
              <a href="https://www.airtek.com.ve/" className="mb-0 website">www.airtek.com.ve</a>
              <p className="mb-2">
                Síguenos en nuestras
                <span className="sm"> Redes Sociales</span>
              </p>
              <div className=" mx-auto">
                <a href="https://www.instagram.com/airtekinternet/">
                  <i className="icon-sm fab fa-instagram me-3"></i>
                </a>
                <a href="https://www.facebook.com/airtekesinternet/">
                  <i className="icon-sm fab fa-facebook me-3"></i>
                </a>
                <a href="https://www.youtube.com/c/AirtekesInternet">
                  <i className="icon-sm fab fa-youtube me-3"></i>
                </a>
                <a href="https://twitter.com/airtekinternet">
                  <i className="icon-sm fab fa-twitter me-3"></i>
                </a>
                <a href="https://t.me/airtekinternet">
                  <i className="icon-sm fab fa-telegram-plane"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
