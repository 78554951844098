import React from "react";
import { Link } from 'react-router-dom'
const NavbarIcon = ({ icon, notif, space, funct, home }) => {
  return (
    <div className="circle position-relative me-3">
      <div className=" mx-auto my-auto">
        <Link to={home ? '/dashboard' : '/'}>
          <i className={`nb-icon ${icon}`} onClick={funct}></i>
        </Link>
      </div>

      {notif ? (
        <span className="position-absolute bottom-0 start-100 bg-danger border border-light rounded-circle span-ntf">
          <span className="visually-hidden">New alerts</span>
        </span>
      ) : (
        ``
      )}
    </div>
  );
};

export default NavbarIcon;
