import { BrowserRouter, Route, Switch } from "react-router-dom";
import Main from "../views/Main/Main";
import Login from "../views/Login/Login";
import { PagosM } from "../views/PagosM/PagosM";
import RecoverPwd from "../views/RecoverPwd/RecoverPwd";

//Context
import { EmpresarialProvider } from "../context/index";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <EmpresarialProvider>
            <Route path="/dashboard" component={Main} />
            <Route path="/pagos-m" component={PagosM} />
            <Route path="/recovery" component={RecoverPwd} />
          </EmpresarialProvider>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
