import React from "react";

const DataPreview = ({ data }) => {
  return (
    <div className="w-100">
      <table className="table container mt-5">
        <thead>
          <tr>
            <th scope="col">Número de Transferencia</th>
            <th scope="col">Comentario</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d, index) => (
            <tr key={index}>
              <td>{d.NumeroTransferencia}</td>
              <td>{d.Comentario}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DataPreview;
