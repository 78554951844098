import React, { useState, useEffect } from "react";
import "../MainComponents.css";
import Chart1 from "./Charts/Chart1";

const Chart = () => {
  const [graph1, setGraph1] = useState(null);
  const [graph2, setGraph2] = useState(null);
  const [graph3, setGraph3] = useState(null);
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "image/png",
      },
    };
    setLoading(true)
    setTimeout(()=>{
      
      fetch(`${process.env.REACT_APP_URL}graf6h`, requestOptions)
        .then((response) => response.text())
        .then((data) => {
          setLoading(false)
          if (data.length > 86) {
            setGraph1(data);
          }
        })
        .catch(error => console.log(error));
  
        
      fetch(`${process.env.REACT_APP_URL}graf24h`, requestOptions)
        .then((response) => response.text())
        .then((data) => {
          setLoading(false)
          if (data.length > 86) {
            setGraph2(data);
          }
        }).catch(error => console.log(error));
  
        
      fetch(`${process.env.REACT_APP_URL}graf48h`, requestOptions)
        .then((response) => response.text())
        .then((data) => {
          setLoading(false)
          if (data.length > 86) {
            setGraph3(data);
          }
        });
    }, 2000)
    
  }, []);

  return (
    <div className="card card-chart h-100">
      <div className="card-body">
        <div className="d-flex  justify-content-between chart-dv">
          <p className="traf mb-0">Tráfico</p>

          <div>
            <ul className="nav nav-pills" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active  rounded-pill"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  6 horas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link  rounded-pill"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  24 horas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link  rounded-pill"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  48 horas
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content text-center  h-100" id="myTabContent">
          <div
            className="tab-pane h-100 fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="d-flex mx-auto my-auto h-100">
              <Chart1 image={graph1}  loading={loading}/>
            </div>
          </div>
          <div
            className="tab-pane fade h-100"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            <div className="d-flex mx-auto my-auto h-100">
              <Chart1 image={graph2} loading={loading}/>
            </div>
          </div>
          <div
            className="tab-pane fade h-100"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            <div className="d-flex mx-auto my-auto h-100">
              <Chart1 image={graph3} loading={loading}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart;
