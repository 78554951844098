import React, { useContext } from "react";
import { EmpresarialContext } from "../../context";
import CardD from "../../components/Main/CardD/CardD";
import CardSA from "../../components/Main/CardSA/CardSA";
import Chart from "../../components/Main/Chart/Chart";
import CardFac from "../../shared/CardSF/CardFac";
import CardSF from "../../shared/CardSF/CardSF";
import Head from "../../shared/Head";
// import Sidebar from "../../shared/Sidebar/Sidebar";
import "./Main.css";

const Main = () => {
  const {Detalles} = useContext(EmpresarialContext)
  return (
    <div className="wrapper w-100 d-flex align-items-stretch">
      {/* <Sidebar /> */}
      <div id="content" className="main-cnt">
        <div className="container main-ct w-100 p-4 pt-2">
          <Head title={"Resumen"} myClassN={"mb-0 resumen"}/>

          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <CardD />
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <Chart />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <CardSF title={"Solicitudes"} detalles={Detalles} iconSolic tab1 />
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <CardFac/>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
              <CardSA qty_i={"2"} qty_t={"2"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
