import React, { Component } from "react";
import "../MainComponents.css";
import icon from "../../../assets/img/globe-icon.png";
// import { Link } from "react-router-dom";

export default class CardSA extends Component {
  state = {
    internet: null,
    internet_qty: null,
    transporte: null,
    transporte_qty: null,
  };

  componentDidMount() {
    this.getservices();
  }

  getservices() {
    if (this.state.internet || this.state.transporte === null) {
      fetch(`${process.env.REACT_APP_URL}servicios-actv`)
        .then((response) => response.json())
        .then((data) => {
          function comparar(a, b) {
            return b.planServicio.localeCompare(a.planServicio);
          }
          this.setState({
            internet: data.internet.sort(comparar),
            internet_qty: data.internet.length,
          });
          this.setState({
            transporte: data.transporte.sort(comparar),
            transporte_qty: data.transporte.length,
          });
        });
    }
  }

  render() {
    return (
      <div className="card-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0 cardsa-title">Servicios Activos</h4>
            <div className="menu-link">
              <i
                className="fas fa-ellipsis-v  menu-icon"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></i>
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between  div-sa">
            <div className="d-flex align-items-center div-gb">
              {this.state.internet &&
                this.state.internet.slice(0, 2).map((internet1, i) => {
                  return (
                    <h6 className="me-3 p-0 mb-0 gb" key={i}>
                      {internet1.planServicio.toUpperCase().replace(/PS/g, "")}
                    </h6>
                  );
                })}
            </div>
            <div className="d-flex align-items-center">
              <p className="mb-0 s-internet me-2">
                Internet (
                {(() => {
                  if (this.state.internet_qty !== null) {
                    return this.state.internet_qty;
                  } else {
                    return "";
                  }
                })()}
                )
              </p>
              <img src={icon} className="globe-icon" alt="globe icon" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between  div-sa">
            <div className="d-flex align-items-center div-gb">
              {this.state.transporte &&
                this.state.transporte.slice(0, 2).map((transporte1, x) => {
                  return (
                    <h6 className="me-3 p-0 mb-0 gb" key={x}>
                      {transporte1.planServicio
                        .toUpperCase()
                        .replace(/PS/g, "")}
                    </h6>
                  );
                })}
            </div>
            <div className="d-flex align-items-center">
              <p className="mb-0 s-transporte me-2">
                Transporte (
                {(() => {
                  if (this.state.transporte_qty !== null) {
                    return this.state.transporte_qty;
                  } else {
                    return "";
                  }
                })()}
                )
              </p>
              <i className="fas fa-network-wired icon-trnsprt"></i>
            </div>
          </div>
          <hr />
          
          
          {/* <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 a-capacidad">Aumentar capacidad</p>
            <Link to="/">
              <i className="fas fa-arrow-circle-right icon-arrw"></i>
            </Link>
          </div> */}

          
        </div>
        <div
          className="modal fade modal-sa"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title cardsa-title" id="exampleModalLabel">
                  Servicios Activos
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center mb-3">
                  <img
                    src={icon}
                    className="globe-icon me-2"
                    alt="globe icon"
                  />
                  <p className="mb-0">Internet ({this.state.internet_qty})</p>
                </div>

                <div className="d-flex flex-wrap mb-2">
                  {this.state.internet &&
                    this.state.internet.map((internet1, i) => {
                      return (
                        <span className="badge rounded-pill bdg-int m-2 gb" key={i}>
                          {internet1.planServicio
                            .toUpperCase()
                            .replace(/PS/g, "")}
                        </span>
                      );
                    })}
                </div>

                <hr className="divider" />

                <div className="d-flex align-items-center mb-3">
                  <i className="fas fa-network-wired icon-trnsprt me-2"></i>
                  <p className="mb-0">
                    Transporte ({this.state.transporte_qty})
                  </p>
                </div>

                <div className="d-flex flex-wrap mb-2">
                  {this.state.transporte &&
                    this.state.transporte.map((transporte1, i) => {
                      return (
                        <span className="badge rounded-pill bdg-int m-2 gb" key={i}>
                          {transporte1.planServicio
                            .toUpperCase()
                            .replace(/PS/g, "")}
                        </span>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
