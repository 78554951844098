import React from "react";
import "./NotFound.css";

const NotFound = ({ icon, message, textStyles }) => {
  return (
    <div className="mx-auto my-auto info-wrp">
    <div className="text-center">
      <i className={`${icon} nfound-icon`}></i>
      <p className={`${textStyles}`}> {message}</p>
    </div>
    </div>
  );
};

export default NotFound;
