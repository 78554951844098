import React, { useState, useEffect } from "react";
import "../MainComponents.css";
import { Link } from "react-router-dom";
import BtnDropdown from "../BtnDropdown/BtnDropdown";

const CardD = () => {
  const [state, setState] = useState("");

  useEffect(()=>{
    setTimeout(()=> {
      fetch(`${process.env.REACT_APP_URL}deuda`)
      .then((response) => response.text())
      .then((data) => {
        setState(data);
        localStorage.setItem(
          "dbt-0",
          Buffer.from(new Intl.NumberFormat("de-DE").format(data)).toString(
            "base64"
          )
        );
      })
      .catch(error => console.error(error));
    },2000)
  }, [])
  
  const getDate = () => {
    const date = new Date
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    return `05/${month}/${year}`
  }
  return (
    <div className="card cardd">
      <div className="card-body">
        <div className="crd-debt">
          <p className="debt-title mb-1">Monto a Pagar</p>
          <h4 className="debt-qty">
            ${new Intl.NumberFormat("de-DE").format(state)}
          </h4>
        </div>

        <div>
          <BtnDropdown />
        </div>

        <div className="mt-4">
          <div className="d-flex justify-content-between">
            <p className="mb-0 exp">Vencimiento</p>
            <p className="mb-0 exp-date">{getDate()}</p>
          </div>
        </div>

        <div className="mt-3 mb-0">
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 link-txt">Reportar múltiples pagos</p>
            <Link to="/pagos-m">
              <i className="fas fa-arrow-circle-right"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardD;
