import {createContext, useState, useEffect, useCallback} from 'react'

const EmpresarialContext = createContext()

const EmpresarialProvider = (props) => {
  const [Detalles, setDetalles] = useState({});
  const [Created1, setCr1] = useState(false);
  const [Widget, setWidget] = useState(false);

  const updData = useCallback(() => {
    if (
      Created1 === false &&
      Object.keys(Detalles).length !== 0 &&
      Widget === true
    ) {
      const script2 = document.createElement("script");

      script2.id = "ze-prefill";
      script2.text = `var myName = JSON.parse(localStorage.getItem('user_data')).nombre;
        var myEmail = JSON.parse(localStorage.getItem('user_data')).email;
        var myNumber = JSON.parse(localStorage.getItem('user_data')).telefono;
    

        zE('webWidget', 'prefill', {
        name: {
          value: myName,
          readOnly: true
        },
        email: {
          
          value: myEmail,
          readOnly: true
          
        },
        phone: {
          value: myNumber,
          readOnly: false
        }, 
        
        })`;

      document.body.appendChild(script2);
      setCr1(true);
    }
  }, [Created1, Detalles, Widget]);

  useEffect(() => {
    const scriptw = document.createElement("script");

    scriptw.id = "ze-snippet";
    scriptw.src =
      "https://static.zdassets.com/ekr/snippet.js?key=a46f9628-7342-4931-aa31-a8b31c347a2b";

    document.body.appendChild(scriptw);
    setWidget(true);

    if (Object.keys(Detalles).length === 0) {
      fetch(`${process.env.REACT_APP_URL}det`)
        .then((response) => response.json())
        .then((data) => {
          setDetalles({
            nombre: data.Nombres,
            id: data.Cedula,
            tipo: data.Tipo_pers,
            telefono: data.Telefonos[0] || '00000000',
            email: data.Email,
            tId: data.tId,
            contrato: data.contrato
          });
          localStorage.setItem(
            "user_data",
            JSON.stringify({
              nombre: data.Nombres,
              id: data.Cedula,
              tipo: data.Tipo_pers,
              telefono: data.Telefono,
              email: data?.Email,
              contrato: data.contr
            })
          );
        });
    }
    updData();
  }, [Created1, Detalles, updData]);
  return (
    <EmpresarialContext.Provider
    value={{
      Detalles,
      Created1,
      Widget
    }}>
      {props.children}
    </EmpresarialContext.Provider>
  )
}
export {
  EmpresarialContext,
  EmpresarialProvider
}