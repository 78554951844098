import React, { useState } from "react";
import Button from "../../../shared/Button/Button";
import "../MainComponents.css";
import Modal1 from "../Modal/Modal";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const BtnDropdown = () => {
  const [modal, setModal] = useState(false);
  const Open = () => setModal(!modal);
  const [msj, setMsj] = useState("");
  const [icon, setIcon] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const resetf = () => {
    reset({ codVerif: "" });
  };

  const onSubmit = (data) => {
    setIsLoading(true);

    fetch(`${process.env.REACT_APP_URL}reportar`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        CodigoVerificacion: data.codVerif,
      }),
    })
      .then((res) => res.text())
      .then((json) => {
        setIsLoading(false);
        if (json.error) {
          throw new Error("Bad response from server");
        }
        if (json === "204") {
          setMsj("El registro de su pago ha sido efectuado de manera exitosa.");
          setIcon(`fas fa-check-circle modal-icon-success`);
          Open();
          resetf();
        }
        if (json === "400") {
          setMsj(
            `El número de transferencia ${data.codVerif} ya se encuentra registrado.`
          );
          setIcon(`far fa-times-circle modal-icon-error`);
          Open();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setMsj(`Su solicitud no pudo ser procesada.`);
        setIcon(`far fa-times-circle modal-icon-error`);
        Open();
      });
  };

  return (
    <div>
      <ErrorMessage
        className="r-errormsg"
        errors={errors}
        name="codVerif"
        as="p"
      />

      <input
        type="text"
        className={`b-input ${errors.codVerif ? "is-invalid" : ""}`}
        placeholder="Número de Transferencia"
        {...register("codVerif", {
          required: "Este campo es obligatorio *",
        })}
      />

      <Button
        text={"Reportar Pago"}
        myClass={"btn btn-dark btn-d rounded-pill w-100"}
        funct={handleSubmit(onSubmit)}
        loading={isLoading}
        loadingMsg={"Reportando Pago"}
      />

      <Modal1 open={modal} msj={msj} close={Open} icon={icon} />
    </div>
  );
};

export default BtnDropdown;
