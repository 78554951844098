import "./Navbar.css";
import React from "react";
import NavbarIcon from "./NavbarIcon";
import UserPfp from "./UserPfp";
// import logo from "../../assets/svg/logo-airtek.svg";

const Navbar = ({ rif, name, title, myClassN, tipo, contr }) => {

  function logout() {
    window.location.replace("/");
    localStorage.clear();
  }


  return (
    <div className="main-nb">
      <nav className="navbar navbar-m mb-3 mt-3">
        <div className="container-fluid p-0">
          
          <div className="d-flex">
            <div className="d-flex  justify-content-between align-items-center">
           
              <h4 className={myClassN}>{title}</h4>
              
              <span>|</span>
            </div>

            <div className="d-flex  justify-content-between align-items-center">
              <p className="mb-0 mt-2 rif">RIF: {tipo}-{rif}</p>
            </div>
          </div>

          <div className="ms-auto d-flex align-items-center">
            {/* <NavbarIcon icon={"fas fa-home"} home /> */}
{/*             <div className="d-flex me-3">
              <NavbarIcon icon={"fas fa-envelope-open-text"} notif />
              <NavbarIcon icon={"fas fa-receipt"} />
              <NavbarIcon icon={"fas fa-bell"} notif />
            </div> */}
            <div className="me-3">
              <UserPfp username={name} contr={contr} />
            </div>
            <div className="d-flex align-items-center">
            <NavbarIcon icon={"fas fa-home"} home />
            <NavbarIcon icon={"fas fa-sign-out-alt ms-1 lg-icon"} funct={logout}/>
              </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
