const TICKET_STATUS = {
  closed: 'Cerrado',
  open: 'Abierto',
  new: 'Nuevo',
  pending: 'Pendiente',
  hold: 'En espera',
  solved: 'Resuelto'
}

const TICKET_CLASS_COLOR = {
  closed: 'closed',
  open: 'open',
  new: 'new',
  pending: 'pending',
  hold: 'hold',
  solved: 'solved'
}

const ticketStatus = (status) => TICKET_STATUS[status]

const ticketColor = (status) => TICKET_CLASS_COLOR[status]

const ticketDate  = (ticketDate) => {
  const date = new Date(ticketDate)
  let options = {year: '2-digit', month: 'numeric', day: 'numeric' };
  const formattedDate = date.toLocaleString('en-GB', options)
  return formattedDate
}
export {
  ticketStatus,
  ticketColor,
  ticketDate
}