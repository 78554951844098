import React from "react";

const Popover = ({ title, text, MainClass, titleClass }) => {
  return (
    <div className={`${MainClass}`} role={"tooltip"}>
      <h6 className={`${titleClass}`}>{title}</h6>
      {text}
    </div>
  );
};

export default Popover;
