import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const LoadingBars = ({bars}) => {
  return (
    <>
      {[...Array(bars)].map((x, i) => (
        <tr key={i}>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
          <td>
            <Skeleton />
          </td>
        </tr>
      ))}
    </>
  );
};

export default LoadingBars;
