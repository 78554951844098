import React from "react";
import { Link } from "react-router-dom";

const UserPfp = ({ username, contr }) => {
  return (
    <div className="d-flex user">
      {/* <img
        src={`https://images.pexels.com/photos/753339/pexels-photo-753339.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940`}
        width="40"
        height="40"
        className="rounded-circle position-relative pfp"
        alt="pfp"
      /> */}

      <div className="dropdown">
        <button
          className="btn btn-light dropdown-toggle shadow-none text-left "
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {username}
          <br></br>
          <span className="text-left codigo">
            Código Cliente: {contr}
            </span>
        </button>
        <ul
          className="dropdown-menu dropdown-pwd"
          aria-labelledby="dropdownMenuButton1"
        >
          <li className="dropdwn-li dropwdown-li-p">
            <Link to="/recovery" className="dropdown-item d-i-user">
           

              <button
                className="btn btn-dark shadow-none rounded-pill w-100 btn-cc"
                type="button"
                aria-expanded="false"
              >
                Cambiar Contraseña
              </button>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UserPfp;
