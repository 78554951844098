import React, {useContext} from "react";
import { EmpresarialContext } from "../context";
import "../views/Main/Main.css";
import Navbar from "./Navbar/Navbar";
import NavbarRsp from "./Navbar/NavbarRsp";

const Head = ({ title, myClassN }) => {
  const {Detalles} = useContext(EmpresarialContext)
  return (
    <div className="row">
      <Navbar
        rif={Detalles?.id}
        name={Detalles?.nombre}
        tipo={Detalles?.tipo}
        contr={Detalles?.contrato}
        title={title}
        myClassN={myClassN}
      />
      <div className="nb-container">
        <NavbarRsp />
      </div>
    </div>
  );
};

export default Head;
