import React, { useState, useEffect } from "react";
import "./Modal.css";
import ReactModal from "react-modal";
import Button from "../../../shared/Button/Button";

const Modal1 = ({ open, msj, close, icon, buttons, funct, funct2 }) => {
  const [openM, setOpen] = useState();

  useEffect(() => {
    setOpen(open);
  }, [open]);

  return (
    <ReactModal
      onRequestClose={close}
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered"
      tabIndex="-1"
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            <button
              type="button"
              className="btn-close close-modal"
              onClick={close}
            ></button>
          </div>
          <div className="modal-body mbdy">
            <div className="text-center">
              <i className={`${icon} modal-icon`}></i>
              <p className="modal-text">{msj}</p>

              {buttons && (
                <div className="m-button-wrapper">
                  <Button
                    text={"cambiar"}
                    myClass={"btn btn-dark rounded-pill btn-ch mb-2"}
                    funct={funct}
                  />
                  <Button
                    text={"cancelar"}
                    myClass={"btn btn-outline-dark btn-c rounded-pill"}
                    funct={funct2}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal1;
